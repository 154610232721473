// @ts-nocheck

import React from 'react'
import PCurveGraph from '../PerformanceCurve/PCurveGraph'

const PreviewWind = ({ windPowerData, onSubmit, onCancel}) => {
    console.log("windPowerData XXX", windPowerData)
  return (
    <div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Wind Justification:</span> 123
        </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Name:</span> 123
        </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Description:</span> 123
        </div>
        <div className='h-52'>
            <PCurveGraph
                data={windPowerData.curveData}
                xAxisLabel={windPowerData.xUnit}
                yAxisLabel={windPowerData.yUnit}
                />
        </div>
        <div className='flex justify-end gap-2 mt-4'>
            <button className='eai-btn-red' onClick={onCancel}>Cancel</button>
            <button className='eai-btn' onClick={onSubmit}> Submit</button>
          </div>
    </div>
  )
}

export default PreviewWind