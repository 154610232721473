// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import { AssetBreadCrumb } from "../components/AssetBreadCrumb";
import { AssetGroupFolder, NoAssetGroup } from "../components/AssetGroupFolder";
import { ReactComponent as PlusIcon } from "../assets/icons/plus.svg";
import { ReactComponent as CopyIcon } from "../assets/icons/copy-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setModalState, modalViewsConst } from "../store/modalSlice";
import LoadingIcon from "../components/LoadingIcon";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { updateCogId } from "../api/awsLamba";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const dialogueRef = useRef(null);
  const plusButtonRef = useRef(null);
  const {
    user,
    assetGroups: assetGroupState,
    assets,
  } = useSelector(({ assets, assetGroups, user }) => ({
    assetGroups,
    assets,
    user,
  }));

  useEffect(() => {
    if (
      user?.permissionDetails?.username &&
      !user.permissionDetails.cognitoIdentityId
    ) {
      // Craig update user info in redux here
      updateCogId();
    }
  }, [user.permissionDetails]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dialogueRef.current &&
        !dialogueRef.current.contains(event.target) &&
        plusButtonRef.current &&
        !plusButtonRef.current.contains(event.target)
      ) {
        setDialogueOpen(false); // Clicked outside the div
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dialogueRef, plusButtonRef]);

  const handleAddAssetGroup = async () => {
    const authSession = await fetchAuthSession();
    const groups =
      authSession?.tokens?.idToken?.payload?.["cognito:groups"] ?? [];
    const assetGroupCount = user.permissionDetails?.assetGroupCount ?? 1; //1 is min

    if (
      groups?.includes("Admin") ||
      (assetGroupState?.groups &&
        Object.keys(assetGroupState?.groups).length < assetGroupCount)
    ) {
      dispatch(
        setModalState({
          modalOpen: true,
          modalView: modalViewsConst.addAssetGroup,
          properties: null,
        })
      );
    } else {
      dispatch(
        setModalState({
          modalOpen: true,
          modalView: modalViewsConst.notification,
          properties: {
            header: "Quick Note",
            details: `Asset Groups are currently being capped at ${assetGroupCount} per user. Please reach out to support@erode.ai for any inquiries`,
          },
        })
      );
    }

    setDialogueOpen(false);
  };

  const handleAddAsset = async () => {
    // const userFromAuth = await Auth.currentAuthenticatedUser();
    // const userFromAuth = await getCurrentUser()
    const authSession = await fetchAuthSession();
    const groups = authSession?.tokens?.idToken?.payload?.["cognito:groups"];
    // const groups = userFromAuth.signInUserSession.accessToken.payload["cognito:groups"];
    // console.log("groups", groups, groups?.includes("Admin"))
    // console.log("user", user)
    const assetCountCap = user.permissionDetails?.assetCount ?? 3; //3 is min
    let assetCount = 0;
    Object.values(assets.dataGroups).forEach(
      (groupAssetList) => (assetCount += groupAssetList.length)
    );

    if (groups?.includes("Admin") || assetCount < assetCountCap) {
      if (Object.keys(assetGroupState.groups).length === 0) {
        dispatch(
          setModalState({
            modalOpen: true,
            modalView: modalViewsConst.addGroupFirst,
            properties: null,
          })
        );
      } else {
        dispatch(
          setModalState({
            modalOpen: true,
            modalView: modalViewsConst.addAssetNEW,
            properties: null,
          })
        );
      }
    } else {
      dispatch(
        setModalState({
          modalOpen: true,
          modalView: modalViewsConst.notification,
          properties: {
            header: "Quick Note",
            details: `Assets are currently being capped at ${assetCountCap} per user. Please reach out to support@erode.ai for any inquiries`,
          },
        })
      );
    }

    // if (assetCount > 9) {
    //   dispatch(
    //     setModalState({
    //       modalOpen: true,
    //       modalView: modalViewsConst.notification,
    //       properties: {
    //         header: "Quick Note",
    //         details:
    //           "Assets are currently being capped at 10 per user. Please reach out to support@erode.ai for any inquiries",
    //       },
    //     })
    //   );
    // } else if (Object.keys(assetGroupState.groups).length > 0) {
    //   dispatch(
    //     setModalState({
    //       modalOpen: true,
    //       modalView: modalViewsConst.addAssetNEW,
    //       properties: null,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     setModalState({
    //       modalOpen: true,
    //       modalView: modalViewsConst.addGroupFirst,
    //       properties: null,
    //     })
    //   );
    // }

    setDialogueOpen(false);
  };

  // const handleQuickSetup = () => {
  //   dispatch(
  //     setModalState({
  //       modalOpen: true,
  //       modalView: modalViewsConst.llmSetupAssets,
  //     })
  //   );
  // }

  //Craig try this
  const renderOptions = () => {
    if (assetGroupState.loading !== "success") {
      return (
        <div className="flex">
          <LoadingIcon pixelSize={200} />
        </div>
      );
    }
    if (Object.keys(assetGroupState?.groups).length === 0) {
      return <NoAssetGroup handleAddAssetGroup={handleAddAssetGroup} />;
    } else {
      return Object.keys(assetGroupState.groups).map((groupId) => (
        <AssetGroupFolder
          key={groupId}
          assetGroup={assetGroupState.groups[groupId]}
        />
      ));
    }
  };
  if (assetGroupState.loading !== "success") {
    return (
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div
          className={`transition-opacity delay-700 duration-500 ease-in-out`}
        >
          <LoadingIcon />
        </div>
      </div>
    );
  }

  // Craig THis works ... part way at least
  // const customS3Grab = async () => {
  //   // CRAIG CHECK IF UNAuthenticated users can grab the data!
  //   const customStorageConfig = {
  //     AWSS3: {
  //       bucket: "erode-s3-storage-cached", // S3 Bucket Name
  //       region: "us-east-1", // S3 Bucket Region
  //       // identityPoolId: 'your-identity-pool-id', // Optional: Amazon Cognito Identity Pool ID if different
  //     },
  //   };
  //   Storage.configure(customStorageConfig); // Temporarily switch to custom configuration
  //   try {
  //     const response = await Storage.get("directory.json", { level: "public" });
  //     console.log("Upload success:", response);
  //   } catch (error) {
  //     console.error("Upload error:", error);
  //   }
  //   // Optionally, reset Storage configuration to default if planning to access the Amplify-managed bucket afterward
  //   // Storage.configure(awsExports.Storage);
  // };

  return (
    <>
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
          <AssetBreadCrumb />
          <div className="flex flex-row h-24 border-y border-gray-200 p-5 justify-left items-center">
            <div className="flex grow items-center">
              <div className="w-12 h-12 border border-gray-200 rounded-lg flex items-center shrink-0 justify-center mr-3">
                <CopyIcon stroke="#A2BBBF" />
              </div>
              <div className="flex-col items-center justify-center">
                <div className="text-gray-900 text-xl font-semibold font-Inter mb-1">
                  Asset Groups
                </div>
                <div className="text-gray-500 text-sm font-normal font-Inter ml-1">
                  Your assets are organized into groups below
                </div>
              </div>
            </div>
          </div>
          <div className="flex grow p-5 flex-wrap h-[calc(100vh_-_9rem)] justify-center md:justify-start overflow-y-auto">
            {renderOptions()}
          </div>
        </div>
        <div className="cursor-pointer absolute group bottom-0 right-0  pb-12 pr-16">
          <div
            ref={dialogueRef}
            className={`${
              dialogueOpen ? "block" : "hidden"
            } py-1 px-2 bg-white  border rounded-lg mr-2`}
          >
            <div
              onClick={handleAddAssetGroup}
              className="cursor-pointer p-2 hover:font-bold"
            >
              New Group
            </div>
            <div onClick={handleAddAsset} className="p-2 hover:font-bold">
              New Asset
            </div>
            {user?.details?.groups?.includes('Admin') && <div onClick={()=>navigate("/home/quicksetup")} className="p-2 hover:font-bold">
              Quick Setup
            </div>}
          </div>
          <div
            ref={plusButtonRef}
            className="flex justify-end pt-4"
            onClick={() => setDialogueOpen(!dialogueOpen)}
          >
            <div className="inset-0 w-[60px] h-[60px] bg-eai-primary-accent rounded-full flex items-center justify-center hover:scale-110 transition-transform">
              <PlusIcon className="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
