// @ts-nocheck
import React from 'react'
import MapViewerReact from '../MapViewerReact'
import MapViewerReactMulti from '../MapViewerReactMulti'

const PreviewAsset = ({ asset, onSubmit, onCancel }) => {
  return (
    <div>
      <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Site Justification:</span> 123
        </div>
      <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Name:</span> 123
        </div>
      <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Asset Description:</span> It's a good site
        </div>
      <div className='flex w-full justify-between'>
        
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Latitude:</span> 123
        </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Longitude:</span> 123
        </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
          <span className="text-black">Elevation:</span> 123
        </div>
      </div>
      <MapViewerReactMulti />
        
        <div className='flex justify-end gap-2 mt-4'>
            <button className='eai-btn-red' onClick={onCancel}>Cancel</button>
            <button className='eai-btn' onClick={onSubmit}> Submit</button>
          </div>
    </div>
  )
}

export default PreviewAsset