// @ts-nocheck
import React, { useState } from 'react'

const PromptArea = ({onSubmit, onCancel}) => {
    const [textState, setTextState] = useState("")
  return (
    <div>
          <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">Provide Details about your site</div>
          <textarea className='p-2 w-full border my-2 h-20' value={textState} onChange={(e)=> setTextState(e.target.value)} />
          <div className='flex justify-end gap-2'>
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px] hover:cursor-pointer hover:underline"> Need customization? </div>
            <button className='eai-btn-red' onClick={onCancel}>Cancel</button>
            <button className='eai-btn' onClick={()=>onSubmit(textState)}> Submit</button>
          </div>
    </div>
  )
}

export default PromptArea