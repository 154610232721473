// @ts-nocheck
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import Map from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { useSelector, useDispatch } from "react-redux";
import { setModalStateProps, modalViewsConst } from "../store/modalSlice";
import { setFlyLocation } from "../store/mapStateSlice";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker"; //CRAIG CHECK LATER

//Layers
import WeatherStationLayer, {
  weatherStationLayerId,
} from "./Layers/WeatherStationLayer";
import AssetLayer, { AssetLayerId } from "./Layers/AssetLayer";
import RainGridLayer from "./Layers/RainGridLayer";

import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import CircleSelect from "./Layers/CircleSelect";
import PointExploration from "./Layers/PointExploration";

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass =
//   require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass =
//   require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const mapBoxToken =
  "pk.eyJ1IjoiY3JhaWdtYWMwMDciLCJhIjoiY2xkbHNlZ3h1MDE2bjNxcGJjYWo5YzRtMCJ9.ezesyu3cASMlnZlJLMkZwg";

const MapViewerReact = ({
  defaultLat = 53.51,
  defaultLng = -103.037,
  offset = 0,
  OnClick,
}) => {
  const mapRef = useRef(null);
  const divRef = useRef(null);
  const [cursor, setCursor] = useState<string>("auto");
  const mapState = useSelector((state) => state.mapState);
  const dispatch = useDispatch();
  const [bottomDistance, setBottomDistance] = useState(0);

  useLayoutEffect(() => {
    const calculateBottomDistance = () => {
      const viewportHeight = window.innerHeight;
      const { top } = divRef.current.getBoundingClientRect();
      setBottomDistance(viewportHeight - top - offset);
    };

    // if(mapRef.current) {
    calculateBottomDistance();
    // }

    // Optional: Recalculate when the window is resized
    window.addEventListener("resize", calculateBottomDistance);

    // Cleanup event listener
    return () => window.removeEventListener("resize", calculateBottomDistance);
  }, [divRef]);

  const handleMapClick = (e) => {
    if (mapState.customMarkerActive) {
      handleCustomMarker(e);
    }
    OnClick(e);
  };

  const handleCustomMarker = (e) => {
    const { lngLat } = e;
    const { lng, lat } = lngLat;
    const coordinates = { lng, lat };
    // CRAIG change this to modalState

    dispatch(
      setModalStateProps({
        properties: coordinates,
        modalView: modalViewsConst.pointMapForm,
        modalOpen: true,
      })
    );
  };

  useEffect(() => {
    if (mapRef.current && mapState.flyLocation) {
      mapRef.current.flyTo({
        center: mapState.flyLocation,
        zoom: 7,
        essential: true,
      });
    }
    return () => {
      dispatch(setFlyLocation(null));
    };
  }, [mapState.flyLocation, mapRef.current]);

  //CRAIG DELETE LATER
  // const onMapLoad = React.useCallback(() => {
  //   mapRef.current.on("click", handleMapClick);
  // }, []);

  // Map click event, don;t delete for now CRAIG DELETE LATER

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  return (
    <div
      ref={divRef}
      style={{
        width: "100%",
        // height: `${bottomDistance - 15 + 256}px`,
        position: "relative",
      }}
      className=""
    >
      <Map
        ref={mapRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleMapClick}
        cursor={cursor}
        // onLoad={onMapLoad}
        mapboxAccessToken={mapBoxToken}
        interactiveLayerIds={[weatherStationLayerId, AssetLayerId]}
        initialViewState={{
          longitude: defaultLng,
          latitude: defaultLat,
          zoom: 7,
        }}
        style={{
          width: "100%",
          height: "800px",
          height: `${bottomDistance - 15}px`,
          position: "relative",
        }}
        mapStyle="mapbox://styles/mapbox/light-v9"
      >
        <AssetLayer ref={mapRef} />
        <WeatherStationLayer ref={mapRef} />
        <RainGridLayer ref={mapRef} />
        <CircleSelect ref={mapRef} />
        <PointExploration ref={mapRef} />
      </Map>
    </div>
  );
};

export default MapViewerReact;
