// @ts-nocheck
import React, { useState, useEffect } from "react";
import { setModalOpen } from '../../store/modalSlice'
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as HomeSmile } from "../../assets/icons/home-smile.svg";
import { useSelector, useDispatch } from "react-redux";
import PromptArea from "./PromptArea";
import NewAssetModal from "../Modal/NewAssetModal";
import PreviewAsset from "./PreviewAsset";
import PreviewWind from "./PreviewWind";
import ProvisonAssetGroup from "./ProvisonAssetGroup";
import PreviewAndSubmit from "./PreviewAndSubmit";
import LoadingIcon from "../LoadingIcon";
import QSA from "./QSAssetsOverview"
import QSAssetsOverview from "./QSAssetsOverview";
import sampleLLM from "./sampleLLMResponse.json"
import { post } from "aws-amplify/api";
import sampleEndpointResponse from "./sampleEndpointResponse.json"
import { addAssetGroup } from "../../store/assetGroupSlice";
import { addAssetsToDataGroups } from "../../store/assetsSlice";
import { useNavigate } from "react-router-dom";


const operationStepEnum = {
  firstQuery: "firstQuery",
  pendingItemsOverview: "pendingItemsOverview",
  previewPendingItems: "previewPendingItems",
  reviewPendingItems: "reviewPendingItems",
  submitting: "submitting"
}

const QuickSetup = () => {
    const [llmResponse, setLLMResponse] = useState({loaded:false})
    const [operationStep, setOperationStep] = useState(operationStepEnum.firstQuery)
    const [bluePrint, setBluePrint] = useState({
      
    }) //this is what is sent to the lambda function to create the assets
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSubmitLLM = (data) => {
        // console.log("sampleLLM", sampleLLM)
            // handle data input
            // const output = {
            //     windPowerData: {
            //       name: "sample wind curve2",
            //       description: "test curve",
            //       curveData: [
            //           {
            //               "x": 0,
            //               "y": 0
            //           },
            //           {
            //               "x": 0.3,
            //               "y": 0.35
            //           },
            //           {
            //               "x": 2,
            //               "y": 2.6
            //           },
            //           {
            //               "x": 4,
            //               "y": 9
            //           },
            //           {
            //               "x": 7,
            //               "y": 30
            //           },
            //           {
            //               "x": 9,
            //               "y": 90
            //           },
            //           {
            //               "x": 10,
            //               "y": 90
            //           },
            //           {
            //               "x": 10.5,
            //               "y": 0
            //           }
            //       ],
            //       xUnit: "m/s",
            //       yUnit: "MW",
            //   },
            //     assetGroup: {
            //       name: "Dons WindFarm"
            //     },
            //     asset: {
            //         // assetGroupId:"b7e7aafd-f4a6-4a6b-958a-af79e84ec286",
            //         description: "testAsset2Delete",
            //         elevation: 0,
            //         lat:25,
            //         lng:27,
            //         name: "test",
            //     }
            // }

            setLLMResponse(sampleLLM)
            setOperationStep(operationStepEnum.pendingItemsOverview)
        }

    const handleAfterPreview = () => {
      // console.log("Yup", llmResponse)
      // if(llmResponse?.windPowerData) {
      //   setLLMResponse((oldResponse) => ({...llmResponse, step:2 }))
      // }
      // else{
      //   setLLMResponse((oldResponse) => ({...llmResponse, step:3 }))
      // }
      setOperationStep(operationStepEnum.reviewPendingItems)
    }

    const handleWindPreview = () => {
      setLLMResponse((oldResponse) => ({...llmResponse, step:3 }))
      setOperationStep(operationStepEnum.submitting)
    }
    // const handleAssetGroupAdd = (assetGroupName) => {
    //   console.log("assetGroupName", assetGroupName)
    //   setLLMResponse((oldResponse) => ({...llmResponse, step:4 }))
    // }

    const HandleSubmitQuickSetup = () => {
      // console.log("It's done!")
      setLLMResponse((oldResponse) => ({...llmResponse, step:5 }))
    }

    const handleCompleteForm = async () => {
      console.log("llmResponse", llmResponse)
      try {
        
          const restOperation = post({
            apiName: "crudopsapi",
            path: "/api/quickcreate",
            options: {
              body: llmResponse,
            },
          });
          // const operationResponse = await restOperation.response;
          // const { response } = await operationResponse.body.json();
          const operationResponse = await restOperation.response;
          console.log("operationResponse", operationResponse)
          const { data, sucess } = await operationResponse.body.json();
          console.log("{ data, sucess }")
          console.log({ data, sucess })
          setOperationStep(operationStepEnum.submitting)
          console.log("sampleEndpointResponse", sampleEndpointResponse)
          const { getAssetGroup } = sampleEndpointResponse.data
          const assets = [...getAssetGroup?.assets?.items]
          const assetGroup = { ...getAssetGroup}
          delete assetGroup.assets
          console.log("assets", assets)
          console.log("assetGroup", assetGroup)
          dispatch(addAssetGroup(assetGroup))
          dispatch(addAssetsToDataGroups({assetGroupId:assetGroup.id, assets }))
          await delay(2000);
          navigate(`/home/group/${assetGroup.id}/asset/${assets[0].id}`)
          
        } catch (e) {
          console.log("e", e)
        }
    }

    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    
    // Craig create - go to my assets*

    const renderStepArea = () => {
      switch(operationStep) {
        case operationStepEnum.firstQuery:
          return <PromptArea onSubmit={handleSubmitLLM} />
        case operationStepEnum.pendingItemsOverview:
        return <QSAssetsOverview
                onCompleteForm={handleCompleteForm}
                llmResponse={llmResponse} 
                onHandlePrevious={()=>setOperationStep(operationStepEnum.firstQuery)}  />
        // return <AssetsOverview onSubmit={handleSubmitLLM} />
        case operationStepEnum.previewPendingItems:
          return <PreviewAsset onSubmit={handleAfterPreview} />
          case operationStepEnum.reviewPendingItems:
            return <PreviewWind windPowerData={llmResponse.windPowerData} onSubmit={handleWindPreview} />
          case operationStepEnum.submitting:
            return (<div className="flex-1  h-64 justify-center bg-white  p-10 ">
              <LoadingIcon
                borderThick={9}
                logoMult={0.6}
                pixelSize={100}
                fullPage={false}
              />
              <div className="flex justify-center">
  
                <div className="my-10">creating assets...</div>
              </div>
            </div>)
          default:
            return <div>Errpor</div>
      }

      
        // if( .operationStepEnum) {
        //     return <PromptArea onSubmit={handleSubmitLLM} />
        // }
        // else if(llmResponse.step === 1) {
            
        //     return <PreviewAsset onSubmit={handleAfterPreview} /> 
        // }
        // else if(llmResponse.step === 2) {
        //   // Craig next here make smaller preview, copy new asset model
        //   return <PreviewWind windPowerData={llmResponse.windPowerData} onSubmit={handleWindPreview} />   // <PreviewAsset onOk />
        // } else if(llmResponse.step === 3) {
        //   // Craig next here make smaller preview, copy new asset model
          
        //   return <ProvisonAssetGroup onSubmit={handleAssetGroupAdd} />   // <PreviewAsset onOk />
        // } else if(llmResponse.step === 4) {
        //   // Craig next here make smaller preview, copy new asset model
          
        //   return <PreviewAndSubmit bluePrint={completedBluePrint} onSubmit={HandleSubmitQuickSetup} />   // <PreviewAsset onOk />
        // } else if(llmResponse.step === 5) {
        //   // Craig next here make smaller preview, copy new asset model
          
        //   return (<div className="flex-1  h-64 justify-center bg-white  p-10 ">
        //     <LoadingIcon
        //       borderThick={9}
        //       logoMult={0.6}
        //       pixelSize={100}
        //       fullPage={false}
        //     />
        //     <div className="flex justify-center">

        //       <div className="my-10">creating assets...</div>
        //     </div>
        //   </div>)
        // } 
        
    }
    
  return (
  //   <div>
  //       <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
  //   <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
  //     <HomeSmile className="h-6 w-6" />
  //   </div>
  //   <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
  //       New Asset Details
  //   </div>
  //   <div
  //     onClick={() => dispatch(setModalOpen(false))}
  //     className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
  //   >
  //     <XClose className="h-6 w-6" />
  //   </div>
  // </div>
    <div className="p-4">
        {/* Lets get started what are you looking for */}
        {/* Asset preview */}
        {/* Looks like you're using a wind curve, Wind curve preview */}
        {/* you don't have an assetGroup, create one now */}
        {/* {provison asset and let's rock} */}
        {renderStepArea()}
    </div>
  // </div>
  )
}



export default QuickSetup