// @ts-nocheck
/* eslint-disable */
import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { record } from "aws-amplify/analytics";
import Authentication from "./components/Authentication";
import { Landing } from "./pages/Landing";
import { NavBar } from "./pages/NavBar";
import { UserSettings } from "./pages/UserSettings";
import MapPage from "./pages/MapPage";
import Modal from "./components/Modal/ModalGlobal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import NotFound from "./pages/NotFound";
import { Provider } from "react-redux";
import { store } from "./store";
import VerticalNav from "./pages/VerticalNav";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import GroupPage from "./pages/GroupPage";
import AlertPage from "./pages/AlertPage";
import DataUpload from "./pages/DataUpload";
import DataUploadForm from "./pages/DataUploadForm";
import AlertManagerPage from "./pages/AlertManagerPage";
import { LoggedInDisplay } from "./components/AuthenticationComponents";
import AdminPage from "./pages/AdminPage";
import withAdminAuth from "./components/HOC/withAdminAuthPage";
import PerformanceCurvePage from "./pages/PerformanceCurvePage";
import ReactGA from "react-ga4";

// const awsconfig = require("./aws-exports").default; //necessary for aws import
import amplifyconfig from "./amplifyconfiguration.json";
import DataUploadEditForm from "./pages/DataUploadEditForm";
import ExplorePage from "./pages/ExplorePage";
import AnalysisListPage from "./pages/AnalysisListPage";
import OrganizationPage from "./pages/OrganizationPage";
import QuickSetupPage from "./pages/QuickSetupPage";
import UploadPage from "./pages/UploadPage";

Amplify.configure(amplifyconfig);
// API.configure(awsconfig)

const queryClient = new QueryClient();

const GAtrackingID = process.env.REACT_APP_GA_TRACKING_ID ?? "false";
console.log("track", process.env);
ReactGA.initialize(GAtrackingID);

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <Modal />
        <BrowserRouter>
          <Routes>
            <Route path="/legacy" element={<NavBar />}>
              <Route path="/legacy" element={<Landing />} />
              <Route path="/legacy/user" element={<Authentication />} />
              <Route path="/legacy/map" element={<MapPage />} />
              {/* <Route path="*" element={<NotFound />} /> */}
            </Route>
            {/* new layout */}
            {/* <Route path="/test" element={<VerticalNav />}>
              <Route path="/test" element={<Dashboard />} />
            </Route> */}
            <Route path="/" element={<Authentication />} />
            <Route path="/home" element={<VerticalNav />}>
              <Route path="/home" element={<Home />} />
              <Route path="/home/group/:groupid" element={<GroupPage />} />
              <Route
                path="/home/analysis"
                element={<AnalysisListPage />}
              />
              <Route
                path="/home/analysis/new"
                element={<ExplorePage />} //Rename Explore Later
              />
              {/* <Route
                path="/home/group/:groupid/asset/:assetid/explore"
                element={<ExplorePage />}
              /> */}
              <Route
                path="/home/group/:groupid/asset/:assetid"
                element={<Dashboard />}
              />
              <Route
                path="/home/group/:groupid/historical"
                element={<div>Historical</div>}
              />
              <Route path="/home/pcurve" element={<PerformanceCurvePage />} />
              <Route path="/home/alert" element={<AlertPage />} />
              <Route
                path="/home/alert/:groupid"
                element={<AlertManagerPage />}
              />
              <Route path="/home/settings" element={<LoggedInDisplay />} />
              <Route path="/home/dataupload" element={<DataUpload />} />
              <Route path="/home/dataupload/new" element={<DataUploadForm />} />
              <Route
                path="/home/dataupload/:custommodelid"
                element={<DataUploadEditForm />}
              />
              <Route
                path="/home/organization/:organizationId"
                element={<OrganizationPage />}
              />
              <Route
                path="/home/quicksetup"
                element={<QuickSetupPage />}
              />
              <Route
                path="/home/upload"
                element={<UploadPage />}
              />
              <Route path="/home/admin" element={<AdminPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
