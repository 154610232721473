// @ts-nocheck
import React, { forwardRef } from "react";
import { Source, Layer } from "react-map-gl";
import { useSelector } from "react-redux";

export const genericPointLayerId = "GENERIC_POINT_LAYER";

const GenericPointLayer = forwardRef((props, mapRef) => {
    const { geojsonData } = props
    // console.log("geojsonData", geojsonData)
    // console.log("geojsonData", geojsonData)
    // const formattedData = {
    //     type: "FeatureCollection",
    //     features: [
    //       {
    //         type: "Feature",
    //         geometry: {
    //           type: "Point",
    //           coordinates: [-103.037, 53.51], // [lng, lat]
    //         },
    //         properties: {
    //           id: "site-1",
    //           name: "Site A",
    //           description: "This is site A",
    //         },
    //       },
    //       {
    //         type: "Feature",
    //         geometry: {
    //           type: "Point",
    //           coordinates: [-101.5, 52.0],
    //         },
    //         properties: {
    //           id: "site-2",
    //           name: "Site B",
    //           description: "This is site B",
    //         },
    //       },
    //       {
    //         type: "Feature",
    //         geometry: {
    //           type: "Point",
    //           coordinates: [-102.2, 54.3],
    //         },
    //         properties: {
    //           id: "site-3",
    //           name: "Site C",
    //           description: "Site C description",
    //         },
    //       },
    //       // Add as many as you like...
    //     ],
    //   };

  return (
    <Source
      id={genericPointLayerId}
      type="geojson"
      data={{ ...geojsonData }}
      cluster={false}
    > 
      <Layer
        visible={true}
        visibility={true ? "visible" : "none"}
        {...{
          id: "GenericPointLayerId",
          type: "circle",
          source: genericPointLayerId,
          paint: {
            "circle-radius": 7,
            "circle-color": "#007cbf",
          },
        }}
      />
    </Source>
  );
});

export default GenericPointLayer;