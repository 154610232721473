// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const modalViewsConst = {
  PointMapForm: "pointMapForm",
  addAssetForm: "addAssetForm",
  editAssetForm: "editAssetForm",
  uploadAssetForm: "uploadForm",
  addAssetAlertForm: "addAssetAlertForm",
  editAssetAlertForm: "editAssetAlertForm",
  brandNewModals: "brandNewModals",
  addAssetGroup: "addAssetGroup",
  addAssetNEW: "addAssetNEW",
  deleteAsset: "deleteAsset",
  deleteAssetModal: "deleteAssetModal",
  deleteAssetGroup: "deleteAssetGroup",
  deletePerformanceCurve: "deletePerformanceCurve",
  deleteSolarEnergyModel: "deleteSolarEnergyModel",
  editAssetGroup: "editAssetGroup",
  editAssetNEW: "editAssetNEW",
  addNewAlert: "addNewAlert",
  editNewAlert: "editNewAlert",
  manageAssetAlertLite: "manageAssetAlertLite",
  addPerformanceCurve: "addPerformanceCurve",
  editPerformanceCurve: "editPerformanceCurve",
  powerCurveGraph: "powerCurveGraph",
  solarEnergyModelPreview: "solarEnergyModelPreview",
  assetPowerCurveEdit: "assetPowerCurveEdit",
  notification: "notification",
  passwordChange: "passwordChange",
  newSolarPowerCurve: "newSolarPowerCurve",
  energyModelSelect: "energyModelSelect",
  processedModelDownload: "processedModelDownload",
  changeProfilePicture: "changeProfilePicture",
  llmSetupAssets: "llmSetupAssets"
};

const initialState = {
  modalOpen: false,
  modalView: null,
  properties: null,
};

const ModalStateSlice = createSlice({
  name: "modalState",
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
    setModalStateProps: (state, action) => {
      state.properties = action.payload;
    },
    setModalState: (state, action) => {
      return action.payload;
    },
  },
});

export const { setModalOpen, setModalStateProps, setModalState } =
  ModalStateSlice.actions; // for non async
export default ModalStateSlice.reducer;
