// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PCurveGraph from "../PerformanceCurve/PCurveGraph";
import { editAsset } from "../../store/assetsSlice";
import { fetchPCurves, fetchSolarEnergyModels } from "../../store/pCurveSlice";
import { setModalOpen } from "../../store/modalSlice";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as PowerIcon } from "../../assets/icons/lightning.svg";
import { ReactComponent as CloudRaining } from "../../assets/icons/cloud-raining-05.svg";
import { ReactComponent as WindIcon } from "../../assets/icons/wind-02.svg";
import { ReactComponent as SunIcon } from "../../assets/icons/sun.svg";

export const foreCastSchema = {
  precip: {
    name: "Precipitation",
    short: "precip",
    Icon: CloudRaining,
  },
  wind: {
    name: "Wind",
    short: "wind",
    Icon: WindIcon,
  },
  solar: {
    name: "Solar",
    short: "solar",
    Icon: SunIcon,
  },
};

const AssetPCurveEdit = ({ asset }) => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [activeForecast, setActiveForecast] = useState("wind");
  const { assets, pCurve } = useSelector(({ assets, pCurve }) => ({
    assets,
    pCurve,
  }));
  const [selectedWind, setSelectedWind] = useState(asset.windCurveId);
  const [selectedSolar, setSelectedSolar] = useState(asset.solarCurveId);

  useEffect(() => {
    dispatch(fetchPCurves());
    dispatch(fetchSolarEnergyModels());
  }, []);

  const windPCurveData = pCurve?.data?.[selectedWind];
  const solarEnergyModelData = pCurve?.solarData?.[selectedSolar];

  const handleUpdateCurve = async () => {
    await dispatch(
      editAsset({
        id: asset.id,
        windCurveId: selectedWind,
        solarCurveId: selectedSolar,
      })
    );
    dispatch(setModalOpen(false));
  };
console.log("windPCurveData", windPCurveData)
  return (
    <div>
      <div className=" flex flow-row grow items-center border-b border-gray-200 p-5">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <PowerIcon stroke="#A2BBBF" className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          Assign Energy Models
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <div className="flex ">
        <div className="flex-1 p-5">
          <div>Select Forecast Type</div>
          <div className="flex cursor-pointer bg-white0 border bg-white border-gray-200 rounded-md overflow-hidden items-center px-1 my-2">
            {Object.keys(foreCastSchema).map((key) => {
              const forecast = foreCastSchema[key];
              const { Icon, short, name } = forecast;
              return (
                <div
                  onClick={() => setActiveForecast(short)}
                  className={`h-7 m-1 flex flex-row ${
                    activeForecast === short
                      ? "bg-eai-primary-accent font-semibold text-white"
                      : "bg-grey-200 text-eai-secondary-blue"
                  } items-center rounded-md`}
                >
                  <Icon
                    stroke={`${activeForecast === short ? "white" : "#84979A"}`}
                    className="h-5 w-5 mx-1 "
                  />
                  <div className=" text-xs mx-3 p-1 font-Inter hidden md:block">
                    {name}
                  </div>
                </div>
              );
            })}
          </div>

          {activeForecast === "wind" && (
            <div>
              <div className="pb-2">Select an Energy Model</div>
              <select
                name="windCurve"
                value={selectedWind}
                onChange={(e) => setSelectedWind(e.target.value)}
                className="flex-1 w-full grow border p-3 mb-5  text-md rounded-md"
              >
                <option value={null}>No Energy Model</option>
                {Object.values(pCurve.data).map((curve) => {
                  return (
                    <option key={curve.id} value={curve.id}>
                      {curve.name}{" "}
                      {asset.windCurveId === curve.id && "- Current"}
                    </option>
                  );
                })}
              </select>
              {windPCurveData && (
                <div>
                  {/* <div>name: {windPCurveData.name}</div> */}
                  <div>{windPCurveData.description}</div>
                </div>
              )}
              {/* <button className="eai-btn-grn" onClick={handleUpdateCurve}>
                Update Asset
              </button> */}
            </div>
          )}
          {activeForecast === "solar" && (
            <div>
              <div className="pb-2">Select an Energy Model</div>
              <select
                name="windCurve"
                value={selectedSolar}
                onChange={(e) => setSelectedSolar(e.target.value)}
                className="flex-1 w-full grow border p-3 mb-5  text-md rounded-md"
              >
                <option value={null}>No Energy Model</option>
                {Object.values(pCurve.solarData).map((eModel) => {
                  return (
                    <option key={eModel.id} value={eModel.id}>
                      {eModel.name}{" "}
                      {asset.solarCurveId === eModel.id && "- Current"}
                    </option>
                  );
                })}
              </select>
              {solarEnergyModelData && (
                <div>
                  {/* <div>name: {windPCurveData.name}</div> */}
                  <div>{solarEnergyModelData.description}</div>
                </div>
              )}
              {/* <button className="eai-btn-grn" onClick={handleUpdateCurve}>
                Update Asset
              </button> */}
            </div>
          )}
        </div>
        <div className="flex-1">
          {activeForecast === "wind" && windPCurveData && (
            <PCurveGraph
              data={windPCurveData.curveData}
              xAxisLabel={windPCurveData.xUnit}
              yAxisLabel={windPCurveData.yUnit}
            />
          )}
          {activeForecast === "solar" && solarEnergyModelData && (
            <>
              {Object.keys(solarEnergyModelData).map((key) => (
                <div key={key}>
                  {key}: {solarEnergyModelData[key]}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="flex flex-row grow space-x-3 p-5">
        <button className="flex-grow eai-btn my-1" type="submit">
          <div className="" onClick={() => dispatch(setModalOpen(false))}>
            Cancel
          </div>
        </button>
        <button
          onClick={handleUpdateCurve}
          className="flex-grow eai-btn-grn my-1"
          type="submit"
          form="my-form"
        >
          <div className="">Accept</div>
        </button>
      </div>
    </div>
  );
};

export default AssetPCurveEdit;
