// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/icons/erode_icon.svg";
import { ReactComponent as HomeIcon } from "../assets/icons/home_icon.svg";
import { ReactComponent as TargetIcon } from "../assets/icons/target-05.svg";
import { ReactComponent as LightningIcon } from "../assets/icons/lightning.svg";
import { ReactComponent as LayersIcon } from "../assets/icons/layers-icon.svg";
import { ReactComponent as BellIcon } from "../assets/icons/bell-icon.svg";
import { ReactComponent as Settings } from "../assets/icons/settings.svg";
import { ReactComponent as Admin } from "../assets/icons/plus.svg";
import { ReactComponent as UploadIcon } from "../assets/icons/upload-02.svg";
import { ReactComponent as BarLineChart } from "../assets/icons/bar-line-chart.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssets } from "../store/assetsSlice";
import { fetchAssetGroups } from "../store/assetGroupSlice";
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { getMeUser, getMyOrganizations, setUserDetails } from "../store/userSlice";
// import useGetMe from "../hooks/useGetMe";
import ReactGA from "react-ga4";
import { identifyUser, configureAutoTrack } from "aws-amplify/analytics";
import ProfilePictureRounded from "../components/ProfilePictureRounded";

// updateCogId
const VerticalNav = () => {
  // const userHook  = useGetMe();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { groupid, assetid } = useParams();

  const [menuExpand, setMenuExpand] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [userLogged, setUserLogged] = useState(false);

  const fetchData = async () => {
    await dispatch(fetchAssets());
    await dispatch(fetchAssetGroups());
  };

  useEffect(() => {
    if (user?.permissionDetails?.id && !userLogged) {
      const identityUser = {
        userId: user.permissionDetails?.id,
        userProfile: {
          email: user.permissionDetails.email,
          name: user.permissionDetails.name,
        },
      };

      setUserLogged(true);
      try {
        if (!user?.details?.groups?.includes("Admin")) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const location = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                };
                // identityUser.userProfile.location = location
                identifyUser(identityUser);
              },
              (error) => {
                identifyUser(identityUser);
              }
            );
          } else {
            identifyUser(identityUser);
          }
          configureAutoTrack({
            enable: true,
            type: "session",
          });

          configureAutoTrack({
            enable: true,
            type: "pageView",
            options: {
              appType: "singlePage",
              urlProvider: () => {
                return window.location.origin + window.location.pathname;
              },
            },
          });
        }
      } catch (e) {
        console.log("Error", e);
      }
    }
  }, [user, userLogged]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    // Function to check screen width
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 540); // 640px is an example breakpoint for small devices, adjust as needed
    };

    // Check screen size on component mount and on window resize
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const fetchUserDetails = async () => {
    try {
      const authSession = await fetchAuthSession();

      if (!authSession.tokens) {
        dispatch(logOutUser());
      } else {
        const details = {
          groups: authSession?.tokens?.idToken?.payload?.["cognito:groups"],
          email: authSession?.tokens?.idToken?.payload?.email,
          username: authSession?.tokens?.idToken?.payload?.preferred_username,
        };

        // Maybe put the two together?
        dispatch(setUserDetails(details));
        dispatch(getMeUser(authSession.userSub)); //Remove later
      }
    } catch (err) {
      navigate("/");
    }
  };

  //if use is not authenticated nav Home // CRAIG ADD SIMILAR TO HOME SCREEN
  useEffect(() => {
    fetchUserDetails();
  }, []);

  //Load Assets
  useEffect(() => {
    //get All User Assets
    fetchData();
  }, []);

  // get user organizations
  useEffect(() => {
    if(!user?.organizations?.loaded) {
      dispatch(getMyOrganizations())
    }
  },[])

  const handleExpand = () => {
    setMenuExpand(true);
  };

  //MENU EXPAND, Try Later
  // <div
  //     onMouseLeave={() => setMenuExpand(false)}
  //       className={` z-40 bg-zinc-900 absolute ${
  //         menuExpand ? "w-[200px]" : "w-[5px]"
  //       }  h-screen -all transition-all duration`}
  //     >
  //       <div className={`${menuExpand ? 'block opacity-100' : 'hidden opacity-0'} transition-opacity duration-1000 delay-1000`}>
  //       <div className="items-center flex flex-col py-6">
  //           <div className="pb-6">
  //             {/* <Logo /> */} Test
  //           </div>
  //           </div>
  //           <div />

  //       </div>location.pathname

  //     </div>
  // return <></>

  const assetView = location.pathname.includes(
    `/home/group/${groupid}/asset/${assetid}`
  );

  return (
    <div className="flex justify-around bg-pattern-01">
      <div className="flex max-w-[1745px] flex-1 border-r-2 border-slate-200 ">
        <div
          onMouseEnter={() => setMenuExpand(true)}
          className={`z-50 min-w-[70px] h-screen bg-zinc-900 ${
            assetView && isSmallScreen ? "hidden" : "flex"
          } flex-col justify-between pb-8`}
        >
          <div className="items-center flex flex-col py-6">
            <div className="pb-6">
              <Logo />
            </div>
            <div
              onClick={() => navigate(`/home`)}
              className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
            >
              <div
                className={`${
                  location.pathname === "/home" ? "nav-icon-selected" : ""
                } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
              >
                <div className="w-6 h-6 relative flex justify-center ">
                  <HomeIcon className="nav-icon" />
                </div>
              </div>
            </div>
            {location.pathname.includes("/home/") ? (
              <>
                {/* <div
                  onClick={() => navigate(`/home/group/${groupid}/historical`)}
                  className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
                >
                  <div
                    className={`${
                      location.pathname.includes("/historical")
                        ? "nav-icon-selected"
                        : ""
                    } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
                  >
                    <div className="w-6 h-6 relative flex justify-center ">
                      <ClockIcon className="nav-icon" />
                    </div>
                  </div>
                </div> */}
                {/* <div
                  onClick={() => navigate(`/home/group/${groupid}`)}
                  className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
                >
                  <div
                    className={`${
                      location.pathname === `/home/group/${groupid}`
                        ? "nav-icon-selected"
                        : ""
                    } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
                  >
                    <div className="w-6 h-6 relative flex justify-center ">
                      <LayersIcon className="nav-icon" />
                    </div>
                  </div>
                </div> */}
              </>
            ) : null}
            <div
              onClick={() => navigate(`/home/pcurve`)}
              className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
            >
              <div
                className={`${
                  location.pathname === `/home/pcurve`
                    ? "nav-icon-selected"
                    : ""
                } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
              >
                <div className="w-6 h-6 relative flex justify-center ">
                  <LightningIcon stroke="#A2BBBF" className="nav-icon" />
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(`/home/dataupload`)}
              className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
            >
              <div
                className={`${
                  location.pathname.includes("/dataupload")
                    ? "nav-icon-selected"
                    : ""
                } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
              >
                <div className="w-6 h-6 relative flex justify-center ">
                  <TargetIcon stroke="#A2BBBF" className="nav-icon" />
                </div>
              </div>
              
              
            </div>
            <div
              onClick={() => navigate(`/home/upload`)}
              className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
            >
              <div
                className={`${
                  location.pathname.includes("/upload")
                    ? "nav-icon-selected"
                    : ""
                } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
              >
                <div className="w-6 h-6 relative flex justify-center ">
                  <UploadIcon stroke="#A2BBBF" className="nav-icon" />
                </div>
              </div>
              
              
            </div>
            {/* {location.pathname.includes("/home") ? (
              <div
                onClick={() => navigate(`/home/alert`)}
                className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
              >
                <div
                  className={`${
                    location.pathname === `/home/group/${groupid}/alerts`
                      ? "nav-icon-selected"
                      : ""
                  } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
                >
                  <div className="w-6 h-6 relative flex justify-center ">
                    <BellIcon className="nav-icon" />
                  </div>
                </div>
              </div>
            ) : null} */}
            {user?.details?.groups?.includes("Admin") ? (
              <div
                onClick={() => navigate(`/home/analysis`)}
                className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
              >
                <div
                  className={`${
                    location.pathname.includes(`/home/analysis`)
                      ? "nav-icon-selected"
                      : ""
                  } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
                >
                  <div className="w-6 h-6 relative flex justify-center ">
                    <BarLineChart className="nav-icon" />
                  </div>
                </div>
              </div>
            ) : null}
            {user?.details?.groups?.includes("Admin") ? (
              <div
                onClick={() => navigate(`/home/admin`)}
                className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
              >
                <div
                  className={`${
                    location.pathname === `/home/admin`
                      ? "nav-icon-selected"
                      : ""
                  } grow shrink basis-0 self-stretch px-3 py-2 rounded-md justify-start items-center gap-2 inline-flex`}
                >
                  <div className="w-6 h-6 relative flex justify-center ">
                    <Admin className="nav-icon" />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="items-center flex flex-col">
            <div className="w-[70px] justify-center items-center inline-flex">
              <ProfilePictureRounded
               pictureURL={user?.permissionDetails?.profilePictureUrl} diameter={50}
               onClick={() => navigate(`/home/settings`)}
               className="cursor-pointer"
               />
              {/* <img
                className="w-12 h-12 rounded-[200px]"
                src="https://picsum.photos/48/48"
              /> */}
            </div>
            <div
              onClick={() => navigate(`/home/settings`)}
              className="w-12 h-12 justify-center items-center inline-flex mb-[11px] cursor-pointer"
            >
              <div className="grow shrink basis-0 self-stretch px-3 py-2  rounded-md justify-start items-center gap-2 inline-flex">
                <div className="w-6 h-6 relative flex justify-center ">
                  <Settings className="nav-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default VerticalNav;
