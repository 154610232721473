// @ts-nocheck
import React, { useState } from 'react'
import MapViewerReact from '../MapViewerReact'
import MapViewerReactMulti from '../MapViewerReactMulti';
import { point } from '@turf/turf';
import { useDispatch } from 'react-redux';
import { modalViewsConst, setModalState } from '../../store/modalSlice';

const QSAssetsOverview = ({onSubmit, onCancel, llmResponse, onHandlePrevious, onCompleteForm }) => {
  const { justification, assetGroup, assets, windEnergyModels } = llmResponse
  // console.log("llmResponse", llmResponse)
  const dispatch = useDispatch()
  const [currentAsset, setCurrentAsset] = useState("overview"); // "overview" | assetId | "complete"

  // Convert the `assets` dictionary keys to an array
  const allAssetIds = Object.keys(assets || {});

  const handleNext = () => {
    // If we are at "overview", go to the first asset (if any)
    if (currentAsset === "overview") {
      if (allAssetIds.length > 0) {
        setCurrentAsset(allAssetIds[0]);
      } else {
        // If no assets at all, maybe go straight to "complete"
        setCurrentAsset("complete");
      }
      return;
    }

    // If we are currently on "complete", do nothing (or handle differently)
    if (currentAsset === "complete") {
      return;
    }

    // Otherwise, we're on some asset. Find its index in the array
    const currentIndex = allAssetIds.indexOf(currentAsset);
    const newIndex = currentIndex + 1;

    // If next index is beyond the array, go to "complete"
    if (newIndex >= allAssetIds.length) {
      setCurrentAsset("complete");
    } else {
      setCurrentAsset(allAssetIds[newIndex]);
    }
  };

  const handlePrevious = () => {
    // If we are at "overview", there's nowhere "before" that, so do nothing
    if (currentAsset === "overview") {
      onHandlePrevious()
      return;
    }

    // If we are at "complete", go back to the last asset
    if (currentAsset === "complete") {
      if (allAssetIds.length > 0) {
        setCurrentAsset(allAssetIds[allAssetIds.length - 1]);
      } else {
        // If no assets, maybe go to "overview"
        setCurrentAsset("overview");
      }
      return;
    }

    // Otherwise, we are on some asset. Find its index.
    const currentIndex = allAssetIds.indexOf(currentAsset);
    const newIndex = currentIndex - 1;

    // If we move before the first asset, go to "overview"
    if (newIndex < 0) {
      setCurrentAsset("overview");
    } else {
      setCurrentAsset(allAssetIds[newIndex]);
    }
  };

  const handleCompleteForm = () => {
    console.log("test")
    onCompleteForm()
  }

  const handlePreviewWind = (pCurveRecord) => {
    dispatch(
          setModalState({
            modalOpen: true,
            modalView: modalViewsConst.powerCurveGraph,
            properties: pCurveRecord,
          })
        );
  }

  const assetGroupCount = assetGroup ? 1 : 0;
  const assetsCount = assets ? Object.keys(assets).length : 0;
  const windCurveCount = windEnergyModels ? Object.keys(windEnergyModels).length : 0;
  
  // Craig move to util - Utility to build one FeatureCollection of all assets + array of points
  function getFeatureCollections(assetsDict) {
    if (!assetsDict) {
      return { points: [], featureCollection: { type: "FeatureCollection", features: [] } };
    }
    const points = [];
    const features = Object.values(assetsDict).map((asset) => {
      const { lat, lng, ...rest } = asset;
      points.push({ lng, lat });
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
        properties: {
          ...rest,
        },
      };
    });

    return {
      points,
      featureCollection: {
        type: "FeatureCollection",
        features,
      },
    };
  }

  const renderDetails = () => {
    if(currentAsset === "overview") {
      return (<>
      <h2 className="text-xl font-bold mb-2">Overview</h2>
      <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
        Details: {justification}
          </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
        Asset Groups: {assetGroupCount}
          </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
        Assets: {assetsCount}
          </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
        Wind Curves: {windCurveCount}
          </div>
      </>)
    } else if(currentAsset === "complete") {
      return <div>Compelte</div>
    }
    const currentAssetObj = assets?.[currentAsset]
    if(currentAssetObj) {
      return(
        <>
        <h2 className="text-xl font-bold mb-2">{currentAssetObj.name}</h2>
            <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
                Justification: {currentAssetObj.justification}
            </div>
            <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
            descriptions: {currentAssetObj.description}
              </div>
            <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
            Latitude: {currentAssetObj.lat}
              </div>
            <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
            Longitude: {currentAssetObj.lng}
              </div>
              {
              currentAssetObj?.windCurveId ?

              <div onClick={()=>handlePreviewWind(windEnergyModels?.[currentAssetObj?.windCurveId])} className="cursor-pointer underline text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
                Preview Wind Energy Model
              </div>
              :
              <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
                Elevation (m): {currentAssetObj.elevation}
              </div>
              
              }
        </>
      )
    } else {
    return(<div>ERROR!</div>)
    }

  }
    

  const handleMapClick = (e) => {
    // Craig next handle feature that was clicked
    // console.log(e)
    
    // console.log("EEExx", e.feature, e.features)
    // console.log("properties", e.features?.[0]?.properties?.id)
    const featureAssetId = e.features?.[0]?.properties?.id
    if(featureAssetId) {
      setCurrentAsset(featureAssetId)
    }
  }
  const { points, featureCollection } = getFeatureCollections(assets);
  const currentAssetObj = assets?.[currentAsset];
  console.log("currentAssetObj", currentAssetObj)
  
  let viewerPoints;

  if(currentAssetObj) {
    // viewerPoints = [currentAssetObj.
    viewerPoints = [{lat:currentAssetObj.lat, lng: currentAssetObj.lng}]
  } else {
    viewerPoints = points
  }
  console.log("viewerPoints", viewerPoints)
  
 
  return (
    <div>
      {renderDetails()}
      {/* <h2 className="text-xl font-bold mb-2">Overview</h2>
      <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
        Details: {justification}
          </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
        Asset Groups: {assetGroupCount}
          </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
        Assets: {assetsCount}
          </div>
        <div className="text-gray-500 text-sm mb-2 font-['Inter'] leading-[18px]">
        Wind Curves: {windCurveCount}
          </div> */}
      <MapViewerReactMulti
        points={viewerPoints}
        onClick={handleMapClick}
        geojsonData={{ genericPointData:featureCollection }}
        offset={60} />
        
        <div className='flex justify-end gap-2 mt-4'>
            {/* <button className='eai-btn-red' onClick={onCancel}>Cancel</button> */}
            <button onClick={handleCompleteForm} className='bg-yellow-200 transition hover:bg-yellow-600 hover:text-white border p-2 rounded'> Submit</button> 
            <button className='eai-btn-red bg-red-600' onClick={handlePrevious}>Previous</button>
            {currentAsset !== "complete" ? <button className='eai-btn bg-green-600' onClick={handleNext}> Next</button> : null}
          </div>
    </div>
  )
}

export default QSAssetsOverview