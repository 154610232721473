// @ts-nocheck
import React from 'react'
import QuickSetup from '../components/QuickSetup/QuickSetup'
import { ReactComponent as LightningIcon } from "../assets/icons/lightning.svg";
import { NestedBreadCrumbs } from '../components/AssetBreadCrumb'

const breadcrumbItems = [
    { route: "/home/dataupload", label: "Quick Setup" },
  ];

const QuickSetupPage = () => {
  return (
    <div className="flex-1 flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
          <NestedBreadCrumbs items={breadcrumbItems}>
            <LightningIcon  //Cody choose an icon
              stroke="#6B7280"
              className="w-5 h-5 ml-2 mr-1 cursor-pointer"
            />
          </NestedBreadCrumbs>
        <QuickSetup />
        </div>
    </div>
  )
}

export default QuickSetupPage