// @ts-nocheck
import React, { useState } from 'react'
import { FileUploader } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';

const UploadPage = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] =useState('')

  
  const renderMessage =() => {
    if (successMessage.length > 0) {
      return (
        <div className='my-4 text-green-500 text-sm text-center'>
                {successMessage}
        </div>
      )
    } else if(errorMessage.length > 0) {
      return (
        <div className='my-4 text-red-500 text-sm text-center'>
                {errorMessage}
        </div>
      )
    } else {
      return (
        <div className='my-4 text-black text-sm text-center'>
                {""}
        </div>
      )
    }
  }

  const handleUploadStart = () => {
    setSuccessMessage("")
    setErrorMessage("")
  }
  
  const handleErrorMessage = (error) => {
    console.log("error", error)
    setErrorMessage(`Error Uploading files`)
  }

  const handleSuccessfullUpload = (file) => {
    console.log("success", file)
    setSuccessMessage("Successfully Uploaded")
  }

  return (
    <div className="flex flex-col grow items-center justify-center lg:flex-row">
      <div className="rounded-lg border border-gray-400 bg-white m-4 2xl:w-1/4 lg:w-4/12 md:w-3/5 sm:w-3/4 p-5">
        <div className="mb-4 text-xl text-center">
            Secure Upload
        </div>
        <div className="mb-4 text-gray-500 text-sm text-center">
            Upload your data for model training
        </div>
        <div>
            <FileUploader
                // acceptedFileTypes={}
                autoUpload={false}
                path={({ identityId }) => {
                  const uploadPath = `private/${identityId}/uploads/`
                  console.log(uploadPath)
                  return uploadPath
                }}
                maxFileCount={6}
                onUploadError={(err)=> handleErrorMessage(err)}
                onUploadStart={(strt)=>handleUploadStart()}
                onUploadSuccess={(file)=>handleSuccessfullUpload(file)}
                onFileRemove={()=>handleUploadStart()}
                isResumable
                />
                {renderMessage()}
               
        </div>
      </div>
    </div>
  )
}

export default UploadPage